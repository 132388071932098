import "../../styles/section.css";
import "./franquicia.scss";

import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Trans, useTranslation } from "react-i18next";
import { graphql } from "gatsby";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Link as AnchorLink } from "react-scroll";

import Layout from "../../components/layout/Layout";
import Seo from "../../components/seo";
import SectionLanding from "../../components/section/SectionLanding";
import FranquiciasForm from "../../components/contact/FranquiciasForm";
import FranquiciasConocerte from "../../components/franquicias/franquiciasConocerte";
import FranquiciaSlider from "../../components/franquicias/franquiciaSlider";

import { anchorLinkProps } from "../../utils/anchorLinkProps";

import MaskSq1 from "../../svg/masks/maskSq1.svg";
import MaskSq2 from "../../svg/masks/maskSq2.svg";
import MaskSq3 from "../../svg/masks/maskSq3.svg";
import MaskSq4 from "../../svg/masks/maskSq4.svg";
import IconDiseño from "../../svg/franquicia/diferenciadores/diseño.svg";
import IconGastronomia from "../../svg/franquicia/diferenciadores/gastronomia.svg";
import IconComunidad from "../../svg/franquicia/diferenciadores/comunidad.svg";
import IconPublico from "../../svg/franquicia/diferenciadores/publico.svg";
import IconModelo from "../../svg/franquicia/diferenciadores/modelo.svg";
import IconCalidad from "../../svg/franquicia/diferenciadores/calidad.svg";

export default function IndexPage({ data }) {
	const { t } = useTranslation();

	const cardStackImages = data.cardStackImages.nodes;
	const sliderImages = data.sliderImages.nodes;

	return (
		<Layout>
			<Seo title={t("Franquicias")} />
			<div className="blackOverlay" id="franquiciaOverlay" />
			<StaticImage
				src={`../../images/franquicia.jpg`}
				className="landing-bg"
				alt="Section background"
				layout="fullWidth"
			/>

			<div className="landingSection">
				<SectionLanding
					title={
						<Trans i18nKey="franquiciaLanding">
							¿Y si el próximo Begin <i>fuera tuyo?</i>
						</Trans>
					}
					orbitalText={t("Abre tu Begin")}
					subtitle={t("Descubre un negocio demostrado")}
					section="franquicia"
				>
					<div className="sectionLandingButtons">
						<AnchorLink
							to="franquiciasContacto"
							{...anchorLinkProps}
							className="underlineButton big"
						>
							<Trans>Contáctanos</Trans>
						</AnchorLink>
					</div>
				</SectionLanding>
			</div>

			{/* Importing masks to use them as clipping masks for backgrounds */}
			<div className="maskImports">
				<MaskSq1 />
				<MaskSq2 />
				<MaskSq3 />
				<MaskSq4 />
			</div>

			<div id="franquiciasIntro" className="section invertColors">
				<div id="sectionContent" />
				<div className="mainTitleCarta">
					<h1 className="lowercase">
						<Trans i18nKey="franquiciaConocerte">
							Queremos <i>conocerte</i>
						</Trans>
					</h1>
				</div>
				<FranquiciasConocerte cardStackImages={cardStackImages} />
			</div>

			<div id="franquiciasDiferenciadores" className="section invertColors">
				<h1 className="lowercase">
					<Trans i18nKey="franquiciaDiferenciadores">
						Nuestros <i>diferenciadores</i>
					</Trans>
				</h1>
				<Swiper
					className="onlyMobile diferenciadoresSlider"
					slidesPerView={"auto"}
					spaceBetween={30}
					centeredSlides={true}
					loop={true}
					modules={[Pagination, Navigation]}
					pagination={true}
					navigation={true}
				>
					<SwiperSlide>
						<div className="franquiciaFlex">
							<div>
								<IconDiseño className="franquiciaIcon" />
								<h2>
									<Trans>Diseño</Trans>
								</h2>
								<p>
									<Trans>Experiencia inmersiva única</Trans>
								</p>
							</div>
							<div>
								<IconGastronomia className="franquiciaIcon" />
								<h2>
									<Trans>Gastronomía</Trans>
								</h2>
								<p>
									<Trans>
										Oferta y concepto muy alineados con las necesidades del
										mercado
									</Trans>
								</p>
							</div>
						</div>
					</SwiperSlide>
					<SwiperSlide>
						<div className="franquiciaFlex">
							<div>
								<IconComunidad className="franquiciaIcon" />
								<h2>
									<Trans>Comunidad</Trans>
								</h2>
								<p>
									<Trans>
										Más de 620.000 seguidores activos en nuestras redes sociales
									</Trans>
								</p>
							</div>
							<div>
								<IconPublico className="franquiciaIcon" />
								<h2>
									<Trans>Amplio público</Trans>
								</h2>
								<p>
									<Trans>
										Segmentos de edad diferentes, estudiantes, oficinas,
										familias...
									</Trans>
								</p>
							</div>
						</div>
					</SwiperSlide>
					<SwiperSlide>
						<div className="franquiciaFlex">
							<div>
								<IconModelo className="franquiciaIcon" />
								<h2>
									<Trans>Modelo ágil</Trans>
								</h2>
								<p>
									<Trans>Alta rotación de mesas, delivery, take away...</Trans>
								</p>
							</div>
							<div>
								<IconCalidad className="franquiciaIcon" />
								<h2>
									<Trans>Máxima calidad</Trans>
								</h2>
								<p>
									<Trans>
										Productos de cercanía y selección rigurosa de nuestros
										proveedores
									</Trans>
								</p>
							</div>
						</div>
					</SwiperSlide>
				</Swiper>
				<div className="franquiciaFlex onlyDesktop">
					<div>
						<IconDiseño className="franquiciaIcon" />
						<h2>
							<Trans>Diseño</Trans>
						</h2>
						<p>
							<Trans>Experiencia inmersiva única</Trans>
						</p>
					</div>
					<div>
						<IconGastronomia className="franquiciaIcon" />
						<h2>
							<Trans>Gastronomía</Trans>
						</h2>
						<p>
							<Trans>
								Oferta y concepto muy alineados con las necesidades del mercado
							</Trans>
						</p>
					</div>
					<div>
						<IconComunidad className="franquiciaIcon" />
						<h2>
							<Trans>Comunidad</Trans>
						</h2>
						<p>
							<Trans>
								Más de 620.000 seguidores activos en nuestras redes sociales
							</Trans>
						</p>
					</div>
					<div>
						<IconPublico className="franquiciaIcon" />
						<h2>
							<Trans>Amplio público</Trans>
						</h2>
						<p>
							<Trans>
								Segmentos de edad diferentes, estudiantes, oficinas, familias...
							</Trans>
						</p>
					</div>
					<div>
						<IconModelo className="franquiciaIcon" />
						<h2>
							<Trans>Modelo ágil</Trans>
						</h2>
						<p>
							<Trans>Alta rotación de mesas, delivery, take away...</Trans>
						</p>
					</div>
					<div>
						<IconCalidad className="franquiciaIcon" />
						<h2>
							<Trans>Máxima calidad</Trans>
						</h2>
						<p>
							<Trans>
								Productos de cercanía y selección rigurosa de nuestros
								proveedores
							</Trans>
						</p>
					</div>
				</div>
			</div>

			<div id="franquiciasCamino" className="section invertColors">
				<div className="mainTitleCarta">
					<h1 className="lowercase">
						<Trans i18nKey="franquiciaCamino">
							Este será <i>el camino</i>
						</Trans>
					</h1>
				</div>
				<div className="franquiciaFlex">
					<div>
						<h2>
							01/
							<br />
							<i>
								<Trans>Contacta</Trans>
							</i>
						</h2>
						<p>
							<Trans>
								Rellena nuestro formulario y nos pondremos en contacto contigo
							</Trans>
							{"  :)"}
						</p>
					</div>
					<div>
						<h2>
							02/
							<br />
							<i>
								<Trans>Entrevista</Trans>
							</i>
						</h2>
						<p>
							<Trans>
								Nos conoceremos más y descubrirás el modelo de negocio en
								franquicia
							</Trans>
						</p>
					</div>
					<div>
						<h2>
							03/
							<br />
							<i>
								<Trans>Visita</Trans>
							</i>
						</h2>
						<p>
							<Trans>
								Descubres nuestros restaurantes y el día a día de un local
							</Trans>
						</p>
					</div>

					<div>
						<h2>
							04/
							<br />
							<i>
								<Trans>Dossiers</Trans>
							</i>
						</h2>
						<p>
							<Trans>
								Te acompañamos con tu plan financiero, búsqueda de local,
								contratos…
							</Trans>
						</p>
					</div>
					<div>
						<h2>
							05/
							<br />
							<i>
								<Trans>Ejecución&nbsp;y&nbsp;formación</Trans>
							</i>
						</h2>
						<p>
							<Trans>
								Recibes el plan de interiorismo, empieza la obra y tu formación
								inicial
							</Trans>
						</p>
					</div>
					<div>
						<h2>
							06/
							<br />
							<i>
								<Trans>Crecimiento</Trans>
							</i>
						</h2>
						<p>
							<Trans>
								Acompañamiento en toda tu vida de franquiciado: formación
								continua, visitas de auditorías y apoyo…
							</Trans>
						</p>
					</div>
				</div>
			</div>

			<div id="franquiciasCarrusel" className="section invertColors">
				<FranquiciaSlider images={sliderImages} />
			</div>

			<div id="franquiciasContacto" className="section invertColors">
				<div className="mainTitleCarta">
					<h1 className="lowercase">
						<Trans i18nKey="franquiciaContacto">
							<i>Contacta</i> con nosotros
						</Trans>
					</h1>
				</div>
				<FranquiciasForm />
			</div>
		</Layout>
	);
}

export const query = graphql`
	query ($language: String!) {
		locales: allLocale(filter: { language: { eq: $language } }) {
			edges {
				node {
					ns
					data
					language
				}
			}
		}

		cardStackImages: allFile(
			filter: {
				extension: { eq: "jpg" }
				absolutePath: { regex: "/franquicias/conocerte/" }
			}
			sort: { order: ASC, fields: name }
		) {
			nodes {
				childImageSharp {
					gatsbyImageData
				}
			}
		}

		sliderImages: allFile(
			filter: {
				extension: { eq: "jpg" }
				absolutePath: { regex: "/franquicias/slider/" }
			}
			sort: { order: ASC, fields: name }
		) {
			nodes {
				childImageSharp {
					gatsbyImageData
				}
			}
		}
	}
`;
